import React, { useState } from "react"
import { Form, Input, Button } from "antd"
import { useHistory } from "react-router-dom"
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import { requests } from "../utils/requests"
// import putSignature from "../../../utils/putSignature"
import EPSProvider from "../providers/EPSProvider"

import { soliqRequest } from "../utils/soliqRequest"
import putSignature from "../utils/putSignature"
function Login() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [ecpKeys, setEcpKeys] = useState([])

  function getPermissionsArray(data) {
    if (!Array.isArray(data)) return []

    return data.reduce((acc, item) => {
      if (item.permissions && Array.isArray(item.permissions)) {
        acc.push(...item.permissions)
      }
      return acc
    }, [])
  }
  const login = async (res) => {
    // setLoading(true)
    try {
      const response = await requests.Auth.login(res)
      if (response?.data) {
        localStorage.setItem(
          "token",
          response?.data?.token || staticData.access_token
        )
        localStorage.setItem("userData", JSON.stringify(response?.data) || {})
        console.log("login")

        //e-imzo login
        // const sellerKey = ecpKeys.find((el) => el.TIN === "311053656")
        // localStorage.setItem("selectedECP", JSON.stringify(sellerKey))
        // const data = { tin: sellerKey?.TIN, inn: sellerKey?.TIN }
        // const sign = await putSignature(data, sellerKey)
        // const resSoliq = await soliqRequest.Soliq.login({ sign: sign })
        // localStorage.setItem("soliqToken", resSoliq?.data?.token?.access_token)

        // setLoading(false)
        history.push("/")
        document.location.reload()
      }
    } catch (error) {
      // setLoading(false)
    }
  }

  const onFinish = (values) => {
    login(values)
  }
  return (
    <div
      className="login"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "20px",
        height: "100vh",
      }}
    >
      <h2 className="text-2xl flex gap-2 ">Vasit Admin</h2>
      <EPSProvider setEcpKeys={setEcpKeys} />

      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please, enter email!",
            },
          ]}
        >
          <Input
            size="large"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please, enter password!" }]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined className="site-form-item-icon" />}
            // type='password'
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            block
            htmlType="submit"
            className="login-form-button"
            size="large"
            loading={loading}
          >
            Sign in
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
export default Login

const staticData = {
  access_token:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vYXBpLnZhc2l0LnV6L2FwaS92MS9hZG1pbi9hdXRoL2xvZ2luIiwiaWF0IjoxNzE4MTEzMjIxLCJleHAiOjE3MTgxMTY4MjEsIm5iZiI6MTcxODExMzIyMSwianRpIjoiSFpkSDBFbTZWWlQ3djA3cyIsInN1YiI6IjEiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.AfAcVpFdkefN8xbHT6_CY7Kke4P2CTmeeGruRB5jSJk",
}
